import React from 'react';

export function Error(props) {

  const { errMsg } = props;

  const key = Math.floor(Math.random()* 1000000)

  if(errMsg.length > 0){
    return (
      <div key={'key'+key} className="alert alert-danger alert-dismissible fade show" role="alert">
        <strong>Holy guacamole!</strong> {errMsg}
        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    );
  }
}

export default Error;