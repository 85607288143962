import React, {useState, useEffect} from 'react';
import { CiSearch } from "react-icons/ci";
import { TbZoomReset } from "react-icons/tb";

function SearchBar(props) {

  const test = localStorage.getItem('userInputTerm');

  const [userInput, setUserInput] = useState(test);

  useEffect(() => {
    localStorage.setItem('userInputTerm', userInput);
  }, [userInput]);

  const handeTextChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleSubmit = (event) => {
      event.preventDefault();
      
    if(userInput.length){
      props.search(userInput);
    }
  }

  const handleReset = () => {
    props.resetSearch();
    setUserInput('');
  }
  
  return (
    <form onSubmit={handleSubmit}>  
      <div className="card">
        <div className="card-header text-uppercase">search for song/artist/album</div>
        <div className="card-body">
          <input type="text" value={userInput} onChange={handeTextChange} aria-label='your favorite song' className="form-control" placeholder="Your favorite song or artist" />
          <div className='text-center mt-3'>
            <button className='btn btn-primary m-1' type="submit">Search <CiSearch /></button>
            <button className='btn btn-danger m-1' onClick={handleReset}>Reset <TbZoomReset /></button>
          </div>
        </div>
      </div>
    </form>

  );
}

export default SearchBar;
