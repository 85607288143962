import React, { useState, useEffect } from 'react';
import './App.css';
import { LuMusic } from 'react-icons/lu';
import { AiOutlineClear } from 'react-icons/ai';
import Swal from 'sweetalert2';

import SearchBar from './components/SearchBar/SearchBar';
import Track from './components/Track/Track';
import Error from './components/error';
import SearchResult from './components/SearchResult/SearchResult';
import Tracklist from './components/Tracklist/Tracklist';
import SpotifyButton from './components/SpotifyButton/SpotifyButton';

//Spotify API data
const CLIENT_ID = 'a9cf9efdff024925b288213bc8c33fbd';
const CLIENT_SECRET = 'dd993f5ac9854c9da632ae10849a2ab5';

//data for the login/auth
const REDIRECT_URL = 'https://jammming.riepelucas.de/';
const SPOTIFY_AUTH_ENDPOINT = 'https://accounts.spotify.com/authorize';
const SCOPE = [
  'user-read-private',
  'user-read-email',
  'playlist-modify-public',
  'playlist-modify-private',
];
const SCOPE_URL_PARAM = SCOPE.join('%20');

const urlSpotifyEndpoint = `${SPOTIFY_AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL}&scope=${SCOPE_URL_PARAM}&response_type=token&show_dialog=true`;

function App() {
  const [resultTrack, setResultTrack] = useState([]);

  const [errMsg, setErrMsg] = useState([]);

  //accessToken and authorization code from Spotify
  const [accessToken, setAccessToken] = useState('');

  //data from the Spotify user
  const [authoCode, setAuthoCode] = useState('');
  const [userId, setUserId] = useState('');
  const [user, setUser] = useState([]);
  const [userName, setUserName] = useState('friend');

  const [playList, setPlayList] = useState([]);
  const statePlaylistName = localStorage.getItem('statePlaylistName');
  const [playListName, setPlayListName] = useState(statePlaylistName);

  const logOut = () => {
    setAuthoCode('');
    setUserId('');
    setUser([]);
    setUserName('friend');

    Swal.fire({
      title: 'Goodbye!',
      text: 'You are logged out!',
      icon: 'info',
    });
  };

  //for local storage
  useEffect(() => {
    localStorage.setItem('statePlaylistName', playListName);
  }, [playListName]);

  useEffect(() => {
    //API Acess Token
    const authParameters = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:
        'grant_type=client_credentials&client_id=' +
        CLIENT_ID +
        '&client_secret=' +
        CLIENT_SECRET +
        '',
    };

    fetch('https://accounts.spotify.com/api/token', authParameters)
      .then((result) => result.json())
      .then((data) => setAccessToken(data.access_token));
  }, []);

  //function to get the data from the URL
  const getRetParaFromSpotify = (hash) => {
    const stringAfterHash = hash.substring(1);
    const paramsUrl = stringAfterHash.split('&');

    const paramsSplitUp = paramsUrl.reduce((accumulater, currentValue) => {
      const [key, value] = currentValue.split('=');
      accumulater[key] = value;
      return accumulater;
    }, {});

    return paramsSplitUp;
  };

  //effect to set the Authentication code
  useEffect(() => {
    if (window.location.hash) {
      const dataReturn = getRetParaFromSpotify(window.location.hash);

      setAuthoCode(dataReturn.access_token.toString());

      window.location.hash = '';
    }
  }, []);

  //effect to set the user Name
  useEffect(() => {
    fetchUser(authoCode);
  }, [authoCode]);

  useEffect(() => {
    setUserName(user.display_name);
    setUserId(user.id);
  }, [user]);

  //function to change the playlistname
  const changeName = (name) => {
    setPlayListName(name);
    console.log(userId);
  };

  //function to add a song to the playlist
  const addSong = (song) => {
    if (!playList.includes(song)) {
      return setPlayList((prev) => [song, ...prev]);
    } else {
      return setErrMsg((prev) => ['song is already in your playlist', ...prev]);
    }
  };

  //function to remove a song
  const removeSong = (songIdToRemove) => {
    setPlayList((playList) =>
      playList.filter((song) => song.id !== songIdToRemove)
    );
  };

  async function search(searchInput) {
    //GET request using search to get the Song
    let trackParameters = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + accessToken,
      },
    };

    // eslint-disable-next-line
    let tracks = await fetch(
      'https://api.spotify.com/v1/search?q=' +
        searchInput +
        '&type=track&limit=8',
      trackParameters
    )
      .then((result) => result.json())
      .then((data) => setResultTrack(data.tracks.items));
  }

  async function fetchUser(token) {
    // eslint-disable-next-line
    let profile = await fetch('https://api.spotify.com/v1/me', {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((result) => result.json())
      .then((data) => setUser(data));
  }

  const resetSearch = () => {
    setResultTrack([]);
  };

  const clearPlaylist = () => {
    setPlayList([]);
    setPlayListName('');
  };

  const saveToSpotify = () => {
    try {
      const authParameters = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authoCode,
        },
        body: JSON.stringify({
          name: playListName,
          public: false,
        }),
      };

      fetch(
        'https://api.spotify.com/v1/users/' + userId + '/playlists',
        authParameters
      )
        .then((result) => result.json())
        .then((data) => saveSongsToPlaylist(data.id, playList, authoCode));
    } catch (errr) {
      console.log(errr);
    }
  };

  const saveSongsToPlaylist = (playListID, playList, authoCode) => {
    const urisPlaylistArray = playList.map((track) => track.uri);

    try {
      const authParameters = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authoCode,
        },
        body: JSON.stringify({
          uris: urisPlaylistArray,
        }),
      };

      fetch(
        'https://api.spotify.com/v1/playlists/' + playListID + '/tracks',
        authParameters
      )
        .then((result) => result.json())
        .then((data) => isReady(data));
    } catch (errr) {
      console.log(errr);
    }
  };

  const isReady = (data) => {
    console.log(data);

    setPlayList([]);
    setPlayListName('');
    setResultTrack([]);

    Swal.fire({
      title: 'Excellent!',
      text: 'your playlist has been created!',
      icon: 'success',
    });
  };

  return (
    <div className="App">
      <header>
        <div className="container-fluid mt-4">
          <div className="row pt-3">
            <div className="col-md-4 text-center mt-5">
              <h1 style={{ fontSize: 60, marginTop: 40 }}>
                Ja<span className="text-success">mmm</span>ing <LuMusic />
              </h1>
            </div>
            <div className="col-md-6">
              <SearchBar search={search} resetSearch={resetSearch} />
            </div>
          </div>
        </div>
      </header>
      <main className="mainInApp">
        <div className="d-flex justify-content-center text-center">
          <div className="col-md-4">
            <h2>Hello {userName}</h2>
          </div>
        </div>
        <div className="d-flex justify-content-center text-center">
          <div className="col-md-4">
            {errMsg.map((msf) => (
              <Error errMsg={msf} />
            ))}
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card resultTracks m-5">
                <div className="card-header">
                  <h2>Results</h2>
                </div>
                <div className="card-body">
                  {/* {resultTrack.map((resultSong) => (
                    <SearchResult key={resultSong.id} song={resultSong} addSong={addSong} search={search} />
                  ))} */}
                  {resultTrack.map((resultSong) =>
                    playList.includes(resultSong) ? (
                      ''
                    ) : (
                      <SearchResult
                        key={resultSong.id}
                        song={resultSong}
                        addSong={addSong}
                        search={search}
                      />
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card resultTracks m-5">
                <div className="card-header">
                  <h2>Songs in your playlist - "{playListName}"</h2>
                  <Tracklist name={playListName} changeName={changeName} />
                </div>
                <div className="card-body">
                  {playList.map((plSong) => (
                    <Track
                      key={plSong.id}
                      song={plSong}
                      removeSong={removeSong}
                    />
                  ))}
                </div>
                <div className="card-footer text-center">
                  <SpotifyButton
                    authoCode={authoCode}
                    urlSpotifyEndpoint={urlSpotifyEndpoint}
                    saveToSpotify={saveToSpotify}
                    logOut={logOut}
                  />
                  <button
                    type="button"
                    onClick={clearPlaylist}
                    className="btn btn-danger btn-lg m-1"
                  >
                    Clear <AiOutlineClear />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer>
        <div className="col-m-12 text-center">
          <p>lucas riepe ©</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
