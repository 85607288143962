import React from 'react';
import { CiTrash } from "react-icons/ci";
import { CiClock1 } from "react-icons/ci";
import { motion } from "framer-motion";



import {millisToMinutesAndSeconds} from './../utilites/utilities';

function Track(props) {

  const { song, removeSong } = props;

  const handelAddClick = () => {
    removeSong(song.id);
  };

  return (
      <motion.div className='card text-center' initial={{ x: -300, opacity: 0 }} animate={{ x: 0, opacity: 1 }} whileHover={{ scale: 1.05 }}>
        <div className='card-body'>
            <div className='row'>

                <div className='col-md-8'>
                    <p className='fs-3 fw-bold'>{song.name}</p>
                    <p className='fs-6'>{song.album.name}</p>
                    <hr/>
                    <p className='fs-5'>{song.artists[0].name} | <CiClock1 /> {millisToMinutesAndSeconds(song.duration_ms)}</p>
                </div>
                <div className='col-md-2'>
                  <img src={song.album.images[1].url} className="img-fluid rounded" alt={song.name+"Album Cover"} aria-label={song.name+"Album Cover"}/>
                </div>

                <div className='col mt-5'>
                    <button aria-label="Remove track" className='btn btn-danger btn-lg' onClick={handelAddClick}> <CiTrash /> </button>
                </div>

            </div>
        </div>
      </motion.div> 
  );
}

export default Track;