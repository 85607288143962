import React from 'react';
import { CiBookmarkPlus } from "react-icons/ci";
import { CiClock1 } from "react-icons/ci";
import { motion, AnimatePresence } from "framer-motion";

import {millisToMinutesAndSeconds} from './../utilites/utilities';


function SearchResult(props) {

  const { song, addSong, search } = props;

  const handelAddClick = () => {
    addSong(song);
  };

  const handleSearchArtist = () => {
    search(song.artists[0].name);
  };

  const handleSearchAlbum = () => {
    search(song.album.name);
  };

  //initial={{opacity: 0, scale: 1.1}} transition={{ ease: "easeOut", duration: 0.5}} animate={{scale: 1, opacity: 1}} whileHover={{ scale: 1.05 }} 
  return (
    <AnimatePresence>
      <motion.div key={song.id} className='card text-center' initial={{ x: 300, opacity: 0 }} animate={{ x: 0, opacity: 1 }} whileHover={{ scale: 1.05 }}>
        <div className='card-body'>
            <div className='row'>

                <div className='col-md-8'>
                    <p className='fs-3 fw-bold'>{song.name}</p>
                    <p className='fs-5'>Album: <button className='text-info' onClick={handleSearchAlbum}> {song.album.name}</button></p>
                    <hr/>
                    <p className='fs-5'>Artist: <button className='text-success' onClick={handleSearchArtist}> {song.artists[0].name}</button> | <CiClock1 /> {millisToMinutesAndSeconds(song.duration_ms)}</p>
                </div>
                <div className='col-md-2'>
                  <img src={song.album.images[1].url} className="img-fluid rounded" alt={song.name+"Album Cover"} aria-label={song.name+"Album Cover"}/>
                </div>

                <div className='col mt-5'>
                    <button aria-label="add track" className='btn btn-warning btn-lg' onClick={handelAddClick}> <CiBookmarkPlus /> </button>
                </div>

            </div>
            <div className='d-flex justify-content-center'>
              <div className='col'>
                <audio controls id="music" preload="true">
                  <source src={song.preview_url}/>
                </audio>
              </div>
            </div>
        </div>
      </motion.div> 
    </AnimatePresence>
  );
}

export default SearchResult;