import React from 'react';
import { FaSpotify } from "react-icons/fa";

function SearchResult(props) {

  const { authoCode, urlSpotifyEndpoint ,saveToSpotify, logOut} = props;

  const handleLogin = () => {
    window.location = urlSpotifyEndpoint;
  };

  const handleLogout = () => {
    logOut();
  };

  const handleSave = () => {
    saveToSpotify();
  };

  if(authoCode.length < 1){

    return (
      <button aria-label="login spotify" className='btn btn-success btn-lg' onClick={handleLogin}>Login to Spotify <FaSpotify /></button>
    );

  }else{

    return (
      <>
      <button aria-label="login spotify" className='btn btn-success btn-lg m-1' onClick={handleSave}>Save to Spotify <FaSpotify /></button>
      <button aria-label="logout spotify" className='btn btn-danger btn-lg m-1' onClick={handleLogout}>Logout <FaSpotify /></button>
      </>
    );

  }

}

export default SearchResult;