import React from 'react';

export function Tracklist({changeName, name}) {

  const handeTextChange = (event) => {
    changeName(event.target.value);
  }

  return (
    <input type="text" value={name} onChange={handeTextChange} aria-label='Name your Playlist' className="form-control" placeholder="Name your Playlist" />
  );
}

export default Tracklist;